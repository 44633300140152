var app_config = {
    endpoints: {
        host: 'https://68d1axt7df.execute-api.us-east-2.amazonaws.com/default/AirtableFormHandler',
        port: '80'
    },
    release: {
        deploy: 'production',
        version: '0.0.1'
    },
}
export default app_config;